import styled from "styled-components";
import { defaultColours } from "fictoan-react";


export const HomeStyled = styled.article`
    #sample-images h4 { margin-left : 48px; }
    
    kbd {
        background-color: ${defaultColours.green70};
        color: ${defaultColours.green10};
        text-shadow: 0 1px 0 ${defaultColours.green};
        box-shadow: 0 3px 0 0 ${defaultColours.green};
    }

    kbd:active {
        background-color: ${defaultColours.green80};
        box-shadow: none;
    }
`;
