//  External dependencies
import React, { useState } from "react";
import {
    BrowserRouter,
    Switch as RouteSwitch,
    Route
} from "react-router-dom";

//  Internal dependencies
import {
    ThemeProvider,
    Element,
    Button
} from "fictoan-react";

//  Local components
import { CodeGreenTheme } from "./styles/CodeGreen.theme";
import { CodeBlueTheme } from "./styles/CodeBlue.theme";
import { GlobalStyle } from "./styles/Global.styled";
import { GlobalFonts } from "./assets/fonts/fonts";
import { Home } from "./pages/Home/Home";
import { About } from "./pages/About/About";

//  Local assets

export const App = () => {
    const [ currentTheme, setCurrentTheme ] = useState(CodeGreenTheme);

    return (
        <ThemeProvider theme={currentTheme}>

            <GlobalStyle />
            <GlobalFonts />

            {/*<Button*/}
            {/*    kind="custom" bgColour="green-90" textColour="white" shape="rounded" shadow="mild" marginRight="nano"*/}
            {/*    onClick={() => setCurrentTheme(CodeGreenTheme)}*/}
            {/*>*/}
            {/*    Code Green*/}
            {/*</Button>*/}

            {/*<Button*/}
            {/*    kind="custom" bgColour="blue-90" textColour="white" shape="rounded" shadow="mild" marginRight="nano"*/}
            {/*    onClick={() => setCurrentTheme(CodeBlueTheme)}*/}
            {/*>*/}
            {/*    Code Blue*/}
            {/*</Button>*/}

            <Element as="div" className={currentTheme.themeName}>
                <BrowserRouter>
                    <RouteSwitch>
                        <Route
                            exact
                            path="/"
                            component={ Home }
                            currentTheme={currentTheme}
                        />

                        <Route
                            exact
                            path="/about"
                            component={ About }
                        />
                    </RouteSwitch>
                </BrowserRouter>
            </Element>
        </ThemeProvider>
    );
}
