import { defaultColours } from "fictoan-react";


export const CodeBlueTheme = {
    themeName : "CodeBlue",

    //  BASICS  ///////////////////////////////////////////////////////////////
    body : {
        bg : "#242943",
    },
    text : {
        font : {
            sans  : "Space Grotesk, sans-serif",
            mono  : "Space Mono, monospace"
        },
        paras: {
            lineHeight : 1.56,
            color      : defaultColours.white
        },
        headings : {
            font : "Space Grotesk, sans-serif",
            color : "#0ccfa5"
        }
    },
    button : {
        font : "Space Grotesk, sans-serif",
        primary : {
            default : {
                bg           : "#439987",
                border       : "#439987",
                text         : defaultColours.white,
                borderRadius : "4px",
            },
        }
    },
    sidebar : {
        isCollapsed : {
            label : {
                text : defaultColours.white,
            }
        },
        header: {
            bg           : defaultColours.white,
            borderBottom : defaultColours.white
        },
        footer: {
            bg        : defaultColours.white,
            borderTop : defaultColours.slate10
        },
    }
}
