//  External deps
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

//  Internal deps
import {
    Element,
    Row,
    Portion,
    Heading,
    Text,
    Button, HRule
} from "fictoan-react";

//  Local components
import { HomeStyled } from "./Home.styled";

//  Local assets
import CodeGreenIDE from "../../assets/images/code-green/cg-ide.png"
import CodeGreenAssembly from "../../assets/images/code-green/assembly.png";
import CodeGreenC from "../../assets/images/code-green/c.png";
import CodeGreenCPP from "../../assets/images/code-green/cpp.png";
import CodeGreenCSS from "../../assets/images/code-green/css.png";
import CodeGreenHaskell from "../../assets/images/code-green/haskell.png";
import CodeGreenHTML from "../../assets/images/code-green/html.png";
import CodeGreenJava from "../../assets/images/code-green/java.png";
import CodeGreenJS from "../../assets/images/code-green/js.png";
import CodeGreenKotlin from "../../assets/images/code-green/kotlin.png";
import CodeGreenMarkdown from "../../assets/images/code-green/markdown.png";
import CodeGreenPHP from "../../assets/images/code-green/php.png";
import CodeGreenPython from "../../assets/images/code-green/python.png";
import CodeGreenRuby from "../../assets/images/code-green/ruby.png";
import CodeGreenScala from "../../assets/images/code-green/scala.png";
import CodeGreenShell from "../../assets/images/code-green/shell.png";
import CodeGreenSQL from "../../assets/images/code-green/sql.png";
import CodeGreenSwift from "../../assets/images/code-green/swift.png";
import CodeGreenTS from "../../assets/images/code-green/typescript.png";


export const Home = (currentTheme) => {
    useEffect(() => {
        document.title = "Code Chroma";

        try {
            window.scroll({
                top: 0,
                left: 0
            });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <HomeStyled>
            {/*  /////////////////////////////////////////////////////////////////////////////////////////////////  */}
            {/*  INTRO  */}
            {/*  /////////////////////////////////////////////////////////////////////////////////////////////////  */}
            <Row sidePadding="huge" gutters="large" marginTop="tiny" marginBottom="micro">
                <Portion>
                    <Heading as="h2" textColour="green-90" marginBottom="nano">Code Chroma — Green</Heading>
                    <Heading as="h4" textColour="green" marginBottom="tiny">
                        A carefully concocted dark theme for VSCode, made of shades of green that soothes the eyes.
                    </Heading>

                    <Element as="div" className="vertically-centre-items push-to-ends">
                        <Heading as="h6">
                            v0.9.18 &mdash;&nbsp;
                            <a href="https://github.com/sujan-s/code-green" target="_blank" rel="noreferrer noopener">
                                Github &#8599;
                            </a>
                        </Heading>

                        <a href="https://www.producthunt.com/posts/code-green?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-code-green" target="_blank" rel="noreferrer">
                            <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=294455&theme=light" alt="Code Green - A verdant VSCode dark theme that soothes the eyes. | Product Hunt"/>
                        </a>
                    </Element>

                    <HRule bgColour="green" className="opacity-40" marginTop="micro" marginBottom="micro" />

                    <Text weight="600">
                        Command + Shift + P, and then paste the code below and hit Enter.
                    </Text>

                    <Heading as="h4">
                        <code>ext install Sujan.code-green</code>
                    </Heading>

                    <Text weight="600">
                        And then Command + Shift + P again, type &ldquo;Color theme&rdquo;, look for Code Green, and hit Enter.
                    </Text>


                </Portion>
            </Row>

            <Row
                id="sample-images"
                sidePadding="tiny" gutters="large"
            >
                <Portion>
                    <Element as="img" src={CodeGreenIDE} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">JS</Heading>
                    <Element as="img" src={CodeGreenJS} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">Typescript</Heading>
                    <Element as="img" src={CodeGreenTS} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">HTML</Heading>
                    <Element as="img" src={CodeGreenHTML} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">CSS</Heading>
                    <Element as="img" src={CodeGreenCSS} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">Assembly</Heading>
                    <Element as="img" src={CodeGreenAssembly} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">C</Heading>
                    <Element as="img" src={CodeGreenC} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">C++</Heading>
                    <Element as="img" src={CodeGreenCPP} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">Haskell</Heading>
                    <Element as="img" src={CodeGreenHaskell} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">Java</Heading>
                    <Element as="img" src={CodeGreenJava} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">Kotlin</Heading>
                    <Element as="img" src={CodeGreenKotlin} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">Markdown</Heading>
                    <Element as="img" src={CodeGreenMarkdown} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">PHP</Heading>
                    <Element as="img" src={CodeGreenPHP} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">Python</Heading>
                    <Element as="img" src={CodeGreenPython} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">Ruby</Heading>
                    <Element as="img" src={CodeGreenRuby} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">Scala</Heading>
                    <Element as="img" src={CodeGreenScala} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">Shell</Heading>
                    <Element as="img" src={CodeGreenShell} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">SQL</Heading>
                    <Element as="img" src={CodeGreenSQL} />
                </Portion>

                <Portion desktopSpan="half">
                    <Heading as="h4">Swift</Heading>
                    <Element as="img" src={CodeGreenSwift} />
                </Portion>

            </Row>
        </HomeStyled>
    );
}
