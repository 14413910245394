import { defaultColours } from "fictoan-react";


export const CodeGreenTheme = {
    themeName : "CodeGreen",

    //  BASICS  ///////////////////////////////////////////////////////////////
    body : {
        bg : "#142e28",
    },
    text : {
        font : {
            sans  : "Space Grotesk, sans-serif",
            mono  : "Space Mono, monospace"
        },
        paras: {
            font       : "Space Grotesk, sans-serif",
            lineHeight : 1.56,
            color      : defaultColours.white
        },
        headings : {
            font : "Space Grotesk, sans-serif",
            color : "#0ccfa5"
        },
        code: {
            inline: {
                bg: defaultColours.green,
                text: defaultColours.green20,
                scale: 80,
            }
        }
    },
    button : {
        font : "Space Grotesk, sans-serif",
        primary : {
            default : {
                bg           : "#439987",
                border       : "#439987",
                text         : defaultColours.white,
                borderRadius : "4px",
            },
        }
    },

    //  RULE  /////////////////////////////////////////////////////////////////
    hr : {
        primary : {
            bg     : defaultColours.green80,
            height : "1px"
        },
        secondary : {
            bg     : defaultColours.green40,
            height : "1px"
        },
        tertiary : {
            bg     : defaultColours.green20,
            height : "1px"
        }
    },

    sidebar : {
        isCollapsed : {
            label : {
                text : defaultColours.white,
            }
        },
        header: {
            bg           : defaultColours.white,
            borderBottom : defaultColours.white
        },
        footer: {
            bg        : defaultColours.white,
            borderTop : defaultColours.slate10
        },
    }
}
